import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'

export function AsideMenuMain() {
  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title='Dashboard'
        fontIcon='bi-layers'
      />
      <AsideMenuItemWithSub
        to='/post'
        title='Post'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/ecommerce/ecm004.svg'
      >
        <AsideMenuItem to='/post' title='Post' hasBullet={true} />
        <AsideMenuItem to='/category' title='Category' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItem
        to='/user'
        icon='/media/icons/duotune/communication/com014.svg'
        title='Users'
        fontIcon='bi-layers'
      />
    </>
  )
}
